import React, { useState, useEffect } from "react";

import Navbar from "./Navbar";
import Footer from "./Footer";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import SecondaryNavbar from "./SecondaryNavbar";
import Photography from "./Photography";
import Coding from "./Coding";
import RealEstate from "./RealEstate";

import aboutVid2 from "../images/aboutVid2.mp4";

export default function Main() {
  const [fadeIn, setFadeIn] = useState(false);
  const [currentPage, setCurrentPage] = useState("About");

  // This method is checking to see what the value of `currentPage` is. Depending on the value of currentPage, we return the corresponding component to render.
  const renderPage = () => {
    if (currentPage === "About") {
      return <About />;
    }
    if (currentPage === "Contact") {
      return <Contact />;
    }
    if (currentPage === "Services") {
      return <Services />;
    }
    if (currentPage === "Photography") {
      return <Photography />;
    }
    if (currentPage === "Coding") {
      return <Coding />;
    }
    if (currentPage === "RealEstate") {
      return <RealEstate />;
    }
    return <Contact />;
  };

  const handlePageChange = (page) => setCurrentPage(page);

  useEffect(() => {
    // Trigger the fade-in effect after a short delay (you can adjust the delay as needed)
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 100);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="about-page-fixing">
      <div className="background-video-container">
        <video className="background-video fadeIn" autoPlay loop muted>
          <source src={aboutVid2} type="video/mp4" />
        </video>
      </div>

      {/* We are passing the currentPage from state and the function to update it */}
      <Navbar
        className={`fade-in-element ${fadeIn ? "fade-in" : ""}`}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
      <SecondaryNavbar
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
      {/* Here we are calling the renderPage method which will return a component  */}
      {renderPage()}
      <Footer />
    </div>
  );
}