import React from 'react';
import ekglogo from '../images/ekglogo.png'

export default function SecondaryNavbar({ currentPage, handlePageChange }){

    return(

        // <div>

        // <div className='navbar-container'>

     

        //     <ul className='navbar-left-ul'>
        //         <li>Commercials</li>
        //         <li>Movies</li>
        //         <li>Music Videos</li>
        //     </ul>
       

        //     <div className='navbar-center'>
        //         <p className='company-name'>Eagle</p>
        //         <img className='logo' src={ekglogo} alt='logo'></img>
        //         {/* <h1 className='company-name'>Eagle Klaw Grip</h1> */}
        //         <p className='company-name'>Klaw</p>
        //     </div>

          

        //     <ul className='navbar-right-ul'>
        //         <a href="#about"
        //   onClick={() => handlePageChange('About')} className={currentPage === 'About' ? 'nav-link active' : 'nav-link'}><li>About</li></a>
        //        <a href="#services"
        //   onClick={() => handlePageChange('Services')} className={currentPage === 'Services' ? 'nav-link active' : 'nav-link'}><li>Services</li></a> 
        //        <a href="#contact"
        //   onClick={() => handlePageChange('Contact')} className={currentPage === 'Contact' ? 'nav-link active' : 'nav-link'}><li>Contact</li></a> 
        //     </ul>
       

        // </div>

        // <p className='compan'>Grip</p>

        // </div>







<div>

        <div className="navbar-placeholder"></div>


<div id="home-nav">
        <ul class="fade-in-element">
        <a href="#about"
          onClick={() => handlePageChange('About')} className={currentPage === 'About' ? 'nav-link active' : 'nav-link'}><li   class="">About</li></a>
        <span>|</span>
        <a href="#services"
          onClick={() => handlePageChange('Services')} className={currentPage === 'Services' ? 'nav-link active' : 'nav-link'}><li class="">Services</li></a>
        <span>|</span>

        <a href="#contact"
          onClick={() => handlePageChange('Contact')} className={currentPage === 'Contact' ? 'nav-link active' : 'nav-link'}><li>Contact</li></a>
        <span>|</span>

        <a href="#photography"
          onClick={() => handlePageChange('Photography')} className={currentPage === 'Photography' ? 'nav-link active' : 'nav-link'}><li class="">Photography</li></a>
        <span>|</span>
        <a href="#coding"
          onClick={() => handlePageChange('Coding')} className={currentPage === 'Coding' ? 'nav-link active' : 'nav-link'}><li class="">Coding</li></a>
        <span>|</span>

        {/* <a href="#musicvideos"
          onClick={() => handlePageChange('MusicVideos')} className={currentPage === 'MusicVideos' ? 'nav-link active' : 'nav-link'}><li>Real Estate</li></a> */}
        
        <a href="#realestate"
   onClick={() => {
      handlePageChange('RealEstate');
      window.open('https://cobigottschalk.bhhstrothconnect.com', '_blank');
   }}
   className={currentPage === 'RealEstate' ? 'nav-link active' : 'nav-link'}>
   <li>Real Estate</li>
</a>

        {/* <span>|</span> */}

        {/* <a class="scroll-link" href="#frequently-asked-questions"><li data-bs-toggle="modal" data-bs-target="#exampleModal">Frequently Asked Questions</li></a> */}
      
       
      </ul>
      </div>

      </div>
    )
}
