import React, { useState } from "react";
import Youtube from "./Youtube";
import aboutVid from "../images/aboutVid.mp4";
import { FaGithub } from "react-icons/fa";
import { BsInfoCircleFill } from "react-icons/bs";
import { projects } from "./projectData";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import image1 from '../images/Designer.png'

export default function About() {
  const [selectedProjectType, setSelectedProjectType] =
    useState("All Projects");
  const [isVisible, setIsVisible] = useState(
    Array(projects.length).fill(false)
  );

  const handleClick = (projectType) => {
    setSelectedProjectType(projectType);
  };

  const toggleVisibility = (index) => {
    const updatedVisibility = [...isVisible];
    updatedVisibility[index] = !updatedVisibility[index];
    setIsVisible(updatedVisibility);
  };


 
  return (
    <div className="portfolio-container ">

      {/* <section className="left-half coding-projects fade-in"> */}
      <section className="coding-projects fade-in">

        <div className="filter-buttons">

          <button onClick={() => handleClick("All Projects")}>All Projects</button>
       
          <button onClick={() => handleClick("Front End")}>Front End</button>
         

          <button onClick={() => handleClick("Back End")}>Back End</button>
         

          <button onClick={() => handleClick("Full Stack")}>Full Stack</button>
        </div>



        <div className="projects-container">
  {projects.map((project, index) => {
    if (
      selectedProjectType === "All Projects" ||
      selectedProjectType === project.projectType
    ) {
      return (
        <div className="project-container fadeIn" key={index}>
          <h3 className="project-name-overlay">
            {project.projectName}
          </h3>
          <a href={project.projectGitHub} target="_blank" rel="noopener noreferrer">
            <FaGithub
              className="project-github-overlay"
              size={44}
              color="black"
              />
          </a>
          <BsInfoCircleFill
            onClick={() => toggleVisibility(index)} // Pass the index to the toggleVisibility function
            className="project-information-overlay"
            size={44}
            color="blue"
            />
          <a href={project.projectSite} target="_blank" rel="noopener noreferrer">
            <img
              className="project-image"
              src={project.projectImage}
              alt={project.projectName}
              />
          </a>
          {isVisible[index] && (
            <div className="project-summary-overlay fade-in">
              {project.projectSummary}
            </div>
          )}
          <p className="project-tech-overlay">{project.projectTech}</p>
        </div>
      );
    }
    return null;
  })}
</div>


      </section>
     


     
    </div>
  );
}

{/* <ul className="coding-ul"> */}
{/* <ul id="home-nav"> */}