import React, { useEffect } from "react";

function GalleryModal({ isOpen, onClose, imageSrc, title }) {
  useEffect(() => {
    // Handle escape key to close the modal
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    // Add event listener for keydown when the modal is open
    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
    }

    // Cleanup the event listener when the component unmounts or modal is closed
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="gallery-modal open" style={{ width: "100%", height: "100%", zIndex: "9" }}>
      <div className="modal-content">
        <img 
          src={imageSrc}
          alt={title}
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          onClick={onClose} // Close the modal on image click
        />

        <div className="button-container">
        <button className="close-btn" onClick={onClose}>
          X
        </button>
        </div>
      </div>
   

    </div>
  );
}

export default GalleryModal;