import React from 'react';
import ekglogo from '../images/ekglogo.png'
import eagleKlawLogo from '../images/eagleKlawLogo.png'
import eagleKla from '../images/Eagle-Kla.png'
import myLogo from '../images/myLogo.png'


// import myLogo from '../images/chameleonlogo2.png'

export default function Navbar({ currentPage, handlePageChange }){

    return(

        // <div id='navbar-div'>

        <div className='navbar-container'>

     

            <ul className='navbar-left-ul'>
            <a href="#photography"
          onClick={() => handlePageChange('Photography')} className={currentPage === 'Photography' ? 'nav-link active' : 'nav-link'}> <li className='li-navbar fadeIn'>Photography</li> </a>
               <a href="#coding"
          onClick={() => handlePageChange('Coding')} className={currentPage === 'Coding' ? 'nav-link active' : 'nav-link'}> <li className='li-navbar fadeIn'>Coding</li> </a>
                {/* <a href="#musicvideos"
          onClick={() => handlePageChange('MusicVideos')} className={currentPage === 'MusicVideos' ? 'nav-link active' : 'nav-link'}><li className='li-navbar'>Real Estate</li></a> */}
          <a onClick={() => { 
              handlePageChange('RealEstate'); 
    window.open('https://cobigottschalk.bhhstrothconnect.com', '_blank');
}} className={currentPage === 'RealEstate' ? 'nav-link active' : 'nav-link'}>
    <li className='li-navbar fadeIn'>Real Estate</li>
</a>
            </ul>
       

            {/* <div className='navbar-center fadeIn'> */}
                <p className='company-name fadeIn'>Cobi</p> 
               
          
                <p className='company-name-grip fadeIn'>Gottschalk</p>

            <ul className='navbar-right-ul'>
                <a href="#about"
          onClick={() => handlePageChange('About')} className={currentPage === 'About' ? 'nav-link active' : 'nav-link'}><li className='li-navbar fadeIn'>About</li></a>
               <a href="#services"
          onClick={() => handlePageChange('Services')} className={currentPage === 'Services' ? 'nav-link active' : 'nav-link'}><li className='li-navbar fadeIn'>Services</li></a> 
               <a href="#contact"
          onClick={() => handlePageChange('Contact')} className={currentPage === 'Contact' ? 'nav-link active' : 'nav-link'}><li className='li-navbar fadeIn'>Contact</li></a> 
            </ul>
       

        </div>


        // </div>
    )
}
