import React from 'react';

export default function Footer(){


    return(

        <div>
<div className='footer-placeholder'></div>

        <div className='footer-container'>
        <h6 className='copyright'>COPYRIGHT © 2023 Cobi Lee Gottschalk - ALL RIGHTS RESERVED.</h6>

        </div>
        </div>
    )
}