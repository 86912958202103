import React from 'react';
import Youtube from 'react-youtube';


    const YoutubeVids = ({videoId, opts  }) => {


  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return <Youtube videoId={videoId} opts={opts} onReady={onReady} />;
};

export default YoutubeVids;