import React from 'react';
import Youtube from "./Youtube";

export default function MusicVideos(){

    const videoId1 = 'VQeW62X8rEA';
    const videoId2 = 'YKeXXnyvMgI';
    const videoId3 = 'hTWKbfoikeg';
    // const videoId4 = 'YKeXXnyvMgI';
  
    const opts1 = {
      height: '200',
      width: '340',
      playerVars: {
        autoplay: 0,
      },
    };

    // const opts2 = {
    //   height: '260',
    //   width: '540',
    //   playerVars: {
    //     autoplay: 0,
    //   },
    // };

    const opts2 = {
      height: '200',
      width: '340',
        playerVars: {
          autoplay: 0,
        },
      };
  
    const opts3 = {
      height: '200',
      width: '340',
      playerVars: {
        autoplay: 0,
      },
    };

    // const opts4 = {
    //   height: '200',
    //   width: '340',
    //   playerVars: {
    //     autoplay: 0,
    //   },
    // };

    return(

        <div className='fadeIn'>
            <div className="navbar-placeholder com-pl"></div>


<div className="main-videos">
             <Youtube className='video1' videoId={videoId1} opts={opts1}/>
            <Youtube className='video2' videoId={videoId2} opts={opts2}/>
             {/* <Youtube className='video3' videoId={videoId3} opts={opts3}/> */}
             
             <iframe src="https://player.vimeo.com/video/486549036?h=d59424c1d5&title=0&portrait=0" width="340" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
{/* <p><a href="https://vimeo.com/486549036">ORVILLE PECK</a> from <a href="https://vimeo.com/alfredwashere">Alfred Marroquin</a> on <a href="https://vimeo.com">Vimeo</a>.</p> */}

<iframe src="https://player.vimeo.com/video/801438105?h=d96dad83ed" width="340" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
{/* <p><a href="https://vimeo.com/801438105">Undercurrent</a></p> */}
         </div>
        </div>
    )
}