import React, { useEffect } from "react";
import servicesImage from "../images/servicesImage.jpg";
import s1 from "../images/Designer.png";
import anime from "animejs"; // Import Anime.js library

export default function Services() {
  useEffect(() => {
    // Wrap every letter in a span
    const textWrapper = document.querySelector(".ml3");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    // Anime.js animation timeline
    anime.timeline({ loop: false }).add({
      targets: ".ml3 .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2250,
      delay: (el, i) => 150 * (i + 1),
    });
    //     .add({
    //       targets: ".ml3",
    //       opacity: 0,
    //       duration: 1000,
    //       easing: "easeOutExpo",
    //       delay: 1000,
    //     });
    // }, []);
    anime.timeline({ loop: false }).add({
      targets: ".ml3 .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2250,
      delay: (el, i) => 150 * (i + 1),
    });
  }, []);

  return (
    <div className="services-component-div">
      {/* <div className="navbar-placeholder">jhj</div> */}

      <section className="left-half">
        <h1
          className="ml3"
          style={{ color: "white", textShadow: "1px 1px 14px rgb(0, 0, 0)" }}
        >
          {/* Services */}
        </h1>

        <div className="circles">
          {/* <i class="bi bi-hammer service-icon"></i>
<i class="bi bi-film service-icon"></i>
<i class="bi bi-camera-reels-fill service-icon"></i>
<i class="bi bi-truck service-icon"></i>
<i class="bi bi-hammer service-icon"></i>
<i class="bi bi-film service-icon"></i>
<i class="bi bi-camera-reels-fill service-icon"></i>
<i class="bi bi-truck service-icon"></i> */}

          {/* <i class="bi bi-camera service-icon" title="Photography"></i> */}
          {/* <i class="bi bi-houses service-icon" title="Real Estate"></i> */}
          {/* <i class="bi bi-book service-icon" title="Teaching"></i> */}
          {/* <i class="bi bi-pc-display-horizontal service-icon" title="Coding"></i> */}
        </div>

        <div className="left-half-infobox">{/* <h2>Hi There</h2> */}</div>
      </section>

      <section className="right-half">
        <div className="section-one fadeIn">
          <h1 className="section-one-h1">Services</h1>

          <p className="p-tag">
            I specialize in creating visually appealing and user-friendly
            landing pages for personal websites, tailored to your unique style
            and needs. In addition, I offer professional photography services,
            capturing stunning portraits, breathtaking landscapes, and striking
            automotive images. As a licensed realtor with Berkshire Hathaway
            HomeServices, I also provide comprehensive real estate services,
            guiding clients through the buying and selling process to help them
            find or sell their dream home with confidence and ease.
          </p>
        </div>

        {/* <section className='sample-section'><img className='s1' src={s1}/><img className='s1' src={s1}/><img className='s1' src={s1}/></section> */}
        <div className="section-two fadeIn">
          <h2 className="subsection-two-heading">Sevices and Pricing</h2>
          <div>
            <p className="p-tag">
              1.) Photography: Whether you need pictures for your restaraunt
              menu or better photos for your dating profile, I offer high
              quality photos for an affordale price. Please click the
              photography link at the top left to learn more.
            </p>
            <p className="p-tag">
              2.) Real Estate: While currently working at the brokerage
              Berkshire Hathaway and Troth, I can assure buyers and sellers that
              they will have access to many resources that will make buying or
              selling a home as stress free as possible.
            </p>
            <p className="p-tag">
              3.) Coding: If you want a custom portfolio website where you wont
              need to pay a fee every single month, take a look at some of the
              different packages I offer.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
