import React, { useEffect, useState } from "react";
// import portraitImage from "../images/portrait.jpg";
// import portraitImage from "../images/P1001581-2.jpg";

import portraitImage from "../images/P1001582.jpg";

// import portraitImage from "../images/hiResBusCard.jpg";
import serviceImage from "../images/servicesImage.jpg";
import contactImage from "../images/contactImage.jpg";
import s1 from "../images/s1.jpg";
import aboutVid from "../images/aboutVid.mp4";
import aboutVid2 from "../images/aboutVid2.mp4";
import eagleKla from "../images/myLogo.png";
import anime from "animejs"; // Import Anime.js library
import GalleryModal from "./GalleryModal.js";

export default function About() {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    // Wrap every letter in a span
    const textWrapper = document.querySelector(".ml3");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    // Anime.js animation timeline
    anime
      .timeline({ loop: false })
      .add({
        targets: ".ml3 .letter",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 2250,
        delay: (el, i) => 150 * (i + 1),
      })

      .add({
        targets: ".ml3",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000,
      });
  }, []);

  //   .add({
  //     targets: ".ml3",
  //     opacity: [1,0],
  //     duration: 2250,
  //     easing: "easeOutQuad",
  //     delay: (el, i) => 150 * (1 - i),
  //   });
  // }, []);

  //   anime.timeline({ loop: false }).add({
  //     targets: ".ml3 .letter",
  //     opacity: [0, 1],
  //     easing: "easeInOutQuad",
  //     duration: 2250,
  //     delay: (el, i) => 150 * (i + 1),
  //   });
  // }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  // ---------------Carousel------------------------

  const [imageIndex, setImageIndex] = useState(0);

  // Define an array of image URLs
  const images = [portraitImage, serviceImage, contactImage, aboutVid];

  // Define an array of text descriptions corresponding to the images
  const imageText = [
    "This is the portrait image description. This is the first image that you will see",
    "This is the service image description.",
    "This is the contact image description.",
    "Now watching a video of the company",
  ];

  const handleLeftButtonClick = () => {
    // Decrease the image index (loop to the end if necessary)
    setImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleRightButtonClick = () => {
    // Increase the image index (loop to the beginning if necessary)
    setImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    // Set the background image URL and text description based on the current index
    const leftHalf = document.querySelector(".left-half");
    leftHalf.style.backgroundImage = `url(${images[imageIndex]})`;

    // Set the text description
    const textDescription = document.querySelector(".image-description");
    textDescription.textContent = imageText[imageIndex];
  }, [imageIndex, images, imageText]);

  // --------------------------Carousel End----------------------

  // --------------------------Modal Start----------------------------

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // --------------------------------------------------------
  return (
    <div className="about-component-div">
      {/* <div className="navbar-placeholder"></div> */}

      {/* ABOUT SECTION */}

      <section className="left-half">
        <h1
          className="ml3"
          style={{ color: "white", textShadow: "1px 1px 14px rgb(0, 0, 0)" }}
        >
          {/* Welcome to my page! */}
        </h1>

        <div className="buttons-container">
          <button className="about-button" onClick={handleLeftButtonClick}>
            <i class="bi bi-arrow-left-square-fill left"></i>
          </button>
          <button className="about-button" onClick={handleRightButtonClick}>
            <i class="bi bi-arrow-right-square-fill right"></i>
          </button>
        </div>

        <div className="background-video-container">
          {/* <video className="background-video fadeIn" autoPlay loop muted>
            <source src={aboutVid} type="video/mp4" />
          </video> */}
        </div>

        <div className="image-description">
          {/* Text description will be updated dynamically */}
        </div>

        <div className="left-half-infobox">{/* <h2>Hi There</h2> */}</div>

        {/* <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
      <div className="card-inner front">
       
        <h2>Front</h2>
        <img className="s1 hide" src={contactImage} />

      </div>
      <div className="card-inner back">

       
        <h2>Back</h2>
        <img className="s1 hide" src={contactImage} />

      </div>
    </div> */}
      </section>

      <div className="right-half">
      <div className="right-half-content">
        {/* <h1 className="h1-greeting">About Us</h1> */}

        <div className="section-one fadeIn">
          <h1 className="section-one-h1">About Me</h1>

          <p className="p-tag">
            Hello there! My name is Cobi Gottschalk and
            here you'll find some of the different services I offer
            as well as some background infomration about me.
          </p>
        </div>

        <div className="section-two fadeIn">
          <h2 className="subsection-two-heading">Education and Experience</h2>
          <p className="p-tag">
            I earned my Bachelor’s/ Master’s degree in Anthropology/Forensic Anthropology
            where I delved into the study of human
            cultures, societies, their development and focused on applying
            anthropological techniques to solve criminal cases and uncover
            historical mysteries. I’m a
            certified coder that specializes in the MERN tech stack making websites and applications. This technical expertise complements my role as a
            licensed real estate agent in California, where I assist clients in
            finding their dream homes and navigating the real estate market. In
            addition to that, I’ve had the rewarding experience of teaching art
            to high school students sharing my passion for creativity and
            guiding young minds in their artistic endeavors has been incredibly
            fulfilling. I’m also an avid photographer that loves capturing
            moments and landscapes, bringing a creative perspective to my work
            and personal life. 
          </p>
        </div>
        {/* <div className="section-two fadeIn">
          <h2 className="subsection-two-heading">Education and Experience</h2>
          <p className="p-tag">
            I earned my Bachelor’s/ Master’s degree in Anthropology/Forensic Anthropology
            where I delved into the study of human
            cultures, societies, their development and focused on applying
            anthropological techniques to solve criminal cases and uncover
            historical mysteries. I’m a
            certified coder that specializes in the MERN tech stack making websites and applications. This technical expertise complements my role as a
            licensed real estate agent in California, where I assist clients in
            finding their dream homes and navigating the real estate market. In
            addition to that, I’ve had the rewarding experience of teaching art
            to high school students sharing my passion for creativity and
            guiding young minds in their artistic endeavors has been incredibly
            fulfilling. I’m also an avid photographer that loves capturing
            moments and landscapes, bringing a creative perspective to my work
            and personal life. 
          </p>
        </div> */}

        <section className="sample-section">
        

          <div className="s1 image-container">
            <img className="about-images" src={serviceImage} alt="Service" />
            <div className="overlay">Experience</div>
          </div>

          <div className="s1 image-container">
            <img className="about-images" src={eagleKla} alt="Service" />
            <div className="overlay">Projects</div>
          </div>

          <div className="s1 image-container" onClick={openModal}>
            <img className="about-images" src={contactImage} alt="Service" />
            <div className="overlay">Gallery</div>
          </div>

          {/* <GalleryModal isOpen={isModalOpen} onClose={closeModal} /> */}
        </section>
      </div>
      </div>
    </div>
  );
}
{
  /* <iframe src="https://giphy.com/embed/SXs8ksv1KzibiueuxS" style={{pointerEvents: 'none'}} className='about-img fadeIn' allowFullScreen></iframe> */
}
