// ContactModal.js
import React from 'react';
// import ekgLogo from '../images/Eagle-Kla.png'
import ekgLogo from '../images/chameleonlogo2.png'


function ContactModal({ isOpen, onClose }) {
  return (
    <div className={`contact-modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h1>Contact Information</h1>
        <img className='modal-company-logo' src={ekgLogo}/>
        {/* Add your contact information here */}
        <p>Phone Number: 1 (818) 555-5555</p>
        <p>Email: cobi.gottschalk@gmail.com</p>
        {/* <p>Address: Los Angeles</p> */}
        <p>Website: cobigottschalk.com</p>
        {/* Close button */}
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default ContactModal;