import React, { useState } from "react";
import { photographyItems } from "./photographyItems";
import GalleryModal from "./GalleryModal.js";

export default function Photography() {
  const [filter, setFilter] = useState("All");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredItems =
    filter === "All"
      ? photographyItems
      : photographyItems.filter((item) => item.category === filter);

  const categories = [
    "All",
    ...new Set(photographyItems.map((item) => item.category)),
  ];

  const openModal = (item) => {
    setSelectedImage(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="photography-component-div fadeIn">
      {/* <div> */}
        <div className="filter-buttons">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setFilter(category)}
              className={filter === category ? "active" : ""}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="portfolio-grid">
          {filteredItems.map((item) => (
            <div
              key={item.id}
              className="portfolio-item fadeIn"
              onClick={() => openModal(item)}
            >
             
              <img src={item.image} alt={item.title} className="zoom"/>
              <h3>{item.title}</h3>
              <p>{item.category}</p>
            </div>
          ))}
        </div>

        {selectedImage && (
          <GalleryModal
            isOpen={isModalOpen}
            onClose={closeModal}
            imageSrc={selectedImage.image}
            title={selectedImage.title}
          />
        )}
      {/* </div> */}
    </div>
  );
}

























// // import React from "react";
// import React, { useState } from "react";
// import Youtube from "./Youtube";
// import photoBanner from "../images/P1001582.jpg";
// import Col from "react-bootstrap/Col";
// import Container from "react-bootstrap/Container";
// import Image from "react-bootstrap/Image";
// import Row from "react-bootstrap/Row";
// import { photographyItems } from "./photographyItems";
// import PhotoModal from "../Components/PhotoModal";
// import GalleryModal from "./GalleryModal.js";


// export default function Photography() {
//   const videoId1 = "khYXgWYMJ8E";
//   const videoId2 = "BBNzttWFaao";
//   const videoId3 = "VGa1imApfdg";

//   const opts1 = {
//     height: "200",
//     width: "340",
//     playerVars: {
//       autoplay: 0,
//     },
//   };

//   const opts2 = {
//     height: "200",
//     width: "340",
//     playerVars: {
//       autoplay: 0,
//     },
//   };

//   const opts3 = {
//     height: "200",
//     width: "340",
//     playerVars: {
//       autoplay: 0,
//     },
//   };

//   const [filter, setFilter] = useState("All");
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [modalShow, setModalShow] = useState(false);

//   const filteredItems =
//     filter === "All"
//       ? photographyItems
//       : photographyItems.filter((item) => item.category === filter);

//   const categories = [
//     "All",
//     ...new Set(photographyItems.map((item) => item.category)),
//   ];

 

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = (item) => {
//     setSelectedImage(item);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <div className="photography-component-div fadeIn">

      
//         <div>
         
//           <div className="filter-buttons">
//             {categories.map((category) => (
//               <button
//                 key={category}
//                 onClick={() => setFilter(category)}
//                 className={filter === category ? "active" : ""}
//                 >
//                 {category}
//               </button>
//             ))}
//           </div>

       
//           <div className="portfolio-grid" onClick={openModal}>
//             {filteredItems.map((item) => (
//               <div
//               key={item.id}
//               className="portfolio-item"
//               onClick={() => openModal(item)}
             
//               >
//                 <img src={item.image} alt={item.title} />
//                 <h3>{item.title}</h3>
//                 <p>{item.category}</p>
//               </div>
//             ))}
//           </div>

       
//           {selectedImage && (
           
//             <GalleryModal isOpen={isModalOpen} onClose={closeModal} imageSrc={selectedImage.image} title={selectedImage.title} />
//             )}
//         </div>         
//     </div>
//   );
// }











{/* <div className="navbar-placeholder com-pl">
</div> */}

{
  /* <div className="main-videos">
  <div className="video">
    {" "}
    <Youtube className="video1" videoId={videoId1} opts={opts1} />
  </div>
  
  <div className="video">
  {" "}
  <iframe
      src="https://player.vimeo.com/video/591626048?h=9a6cab040f"
      width="340"
      height="200"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      ></iframe>
      </div>
      
      <div className="video">
      <iframe
      src="https://player.vimeo.com/video/626840069?h=81f344f58b"
      width="340"
      height="200"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div className="video">
    <iframe
      style={{ backgroundColor: "black" }}
      src="https://player.vimeo.com/video/242115392?h=de5f1aeafe"
      width="340"
      height="200"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div className="video">
    <iframe
      style={{ backgroundColor: "black", border: "black" }}
      src="https://player.vimeo.com/video/870070377?h=949cb164ae"
      width="340"
      height="200"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div> */
}
