import React, { useState, useEffect } from "react";
import contactImage from "../images/contactImage.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import useRef from "react";
import emailjs from "@emailjs/browser";
import anime from "animejs"; // Import Anime.js library
import ContactModal from "./ContactModal.js";

import { Container, Form, Button } from "react-bootstrap";

export default function Contact() {
  const form = useRef;

  function stopFOUC(event) {
    var i = 0;
    var txt = "Thank you! Your form was submitted.";
    var speed = 50;

    function typeWriter() {
      if (i < txt.length) {
        document.querySelector(".submit-btn").value += txt.charAt(i);
        i++;
        setTimeout(typeWriter, speed);
      }
    }

    event.preventDefault();
    let passedValidation = true;

    const formInputs = document.querySelectorAll("input");
    formInputs.forEach((formInput) => {
      if (formInput.value.length === 0) passedValidation = false;
    });

    if (
      !new RegExp("[A-Za-z0-9.]+@[A-Za-z0-9.]+.[A-Za-z0-9.]{1,10}").test(
        document.getElementById("email").value
      )
    ) {
      passedValidation = false;
    }

    if (document.querySelector("textarea").value.length === 0) {
      passedValidation = false;
    }

    if (passedValidation) {
      // Clear all fields

      emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          "template_fonglvd",
          form.current,
          "3yYZVuNYPo1KCexYf"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      formInputs.forEach((formInput) => {
        formInput.value = "";
      });
      document.querySelector("textarea").value = "";
      typeWriter();
    } else {
      alert("All fields are required!");
    }
  }

  useEffect(() => {
    // Wrap every letter in a span
    const textWrapper = document.querySelector(".ml3");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    // Anime.js animation timeline
    anime.timeline({ loop: false }).add({
      targets: ".ml3 .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2250,
      delay: (el, i) => 150 * (i + 1),
    });

    anime.timeline({ loop: false }).add({
      targets: ".ml3 .letter",
      opacity: [0, 1],
      easing: "easeInOutQuad",
      duration: 2250,
      delay: (el, i) => 150 * (i + 1),
    });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="contact-component-div">
      {/* <div className="navbar-placeholder"></div> */}

      {/* <img className='about-img' src={contactImage} alt='Woman performing on stage.' /> */}
      <section className="left-half">
        {/* <h1 class="ml3" style={{color: "white"}}>Welcome to Eagle Klaw Grip</h1> */}
        <h1
          className="ml3"
          style={{ color: "white", textShadow: "1px 1px 14px rgb(0, 0, 0)" }}
        >
          {/* Looking Forward to Working with you! */}
        </h1>

        <div className="left-half-infobox">{/* <h2>Hi There</h2> */}</div>
      </section>

      <section className="right-half">
        {/* <h1>Contact Us!</h1> */}

        <div className="section-one fadeIn">
          <h1 className="section-one-h1">Contact Me</h1>

          <p style={{ padding: "0px 20px" }} className="p-tag">
            Please feel to reach out if you have any questions! You can send me
            an email, fill out my contact form, call, or get in touch through my
            social media!
          </p>
        </div>

        {/* <h2 className="subsection-two-heading fadeIn">Online Contact Form</h2> */}

        <Form
          className="form-container fadeIn"
          ref={form}
          onSubmit={(event) => stopFOUC(event)}
        >
          <Form.Group className="form-group">
            <Form.Label className="form-label">Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="John Smith"
              name="from_name"
              id="from_name"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label className="form-label">Email address</Form.Label>
            <Form.Control
              id="email"
              type="email"
              placeholder="name@example.com"
              pattern="[A-Za-z0-9\.]+@[A-Za-z0-9\.]+\.[A-Za-z0-9\.]{1,10}"
              name="email"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label className="form-label">Message</Form.Label>
            <Form.Control name="message" as="textarea" rows={5} />
          </Form.Group>
          <Button
            className="submit-btn"
            as="input"
            type="submit"
            value="Send"
          />
        </Form>

        <div className="contact-icons">
          <a className="email-link" href="mailto: cobi.gottschalk@gmail.com">
            <i className="bi bi-envelope-fill email-icon fadeIn"></i>
          </a>
          <a className="phone-link" href="tel:+16615555555">
            <i className="bi bi-telephone-fill phone-icon fadeIn"></i>
          </a>
          {/* <i className="bi bi-card-text form-icon fadeIn"></i> */}
          <a
            className="email-link"
            href="https://www.facebook.com/cobi.gottschalk"
            target="_blank"
          >
            <i class="bi bi-facebook form-icon fadeIn"></i>
          </a>
          <a
            className="email-link"
            href="https://www.linkedin.com/in/cobi-gottschalk-6841b1165/"
            target="_blank"
          >
            <i class="bi bi-linkedin form-icon fadeIn"></i>
          </a>

          <i class="bi bi-info-circle info-icon fadeIn" onClick={openModal}></i>
          <ContactModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </section>
    </div>
  );
}
