import littleBird from "../images/littleBird.jpg";
import portraitImage from "../images/P1001582.jpg";
// import desertImage from "../images/DesertSunset.jpg";
import escaladeImage from "../images/escalade.jpg";
import flowerImage from "../images/flowers.JPG";
import nordstromImage from "../images/Nordstrom.jpg";
import blueDesert from "../images/desertBlue2.jpg";
import birdRoof from "../images/birdRoof.jpg";
import redDesertSunset from "../images/redDesertSunset.jpg";
import santaMonicaBoardWalk from "../images/santaMonicaBoardWalk.jpg";
// import veniceBoardWalk from "../images/veniceBoardWalk.jpg";
import veniceBoardWalk2 from "../images/veniceBoardWalk2.jpg";
import sunsetCowboy from "../images/sunsetCowboy.jpg";
import cowboy from "../images/cowboy.jpg";
import joshuaCrow from "../images/joshuaCrow.jpg";

export const photographyItems = [
  {
    id: 1,
    category: "Wild Life",
    title: "Project 1",
    image: `${littleBird}`,
  },
  {
    id: 2,
    category: "Portrait",
    title: "Project 2",
    image: `${portraitImage}`, 
  },
  {
    id: 3,
    category: "Automotive",
    title: "Project 3",
    image: `${escaladeImage}`, 
  },
  {
    id: 4,
    category: "Botanical",
    title: "Project 4",
    image: `${flowerImage}`, 

  },
  {
    id: 5,
    category: "Street",
    title: "Project 5",
    image: `${nordstromImage}`, 
    
  },
  {
    id: 6,
    category: "Landscape",
    title: "Project 6",
    image: `${blueDesert}`, 
    
  },
  {
    id: 7,
    category: "Wild Life",
    title: "Project 7",
    image: `${birdRoof}`, 
    
  },
  {
    id: 8,
    category: "Landscape",
    title: "Project 8",
    image: `${redDesertSunset}`, 
    
  },
  {
    id: 9,
    category: "Street",
    title: "Project 9",
    image: `${santaMonicaBoardWalk}`,   
  },
  {
    id: 10,
    category: "Street",
    title: "Project 10",
    image: `${veniceBoardWalk2}`,   
  },
  {
    id: 11,
    category: "Portrait",
    title: "Project 11",
    image: `${sunsetCowboy}`,   
  },
  {
    id: 12,
    category: "Wild Life",
    title: "Project 12",
    image: `${joshuaCrow}`,   
  },
  {
    id: 13,
    category: "Portrait",
    title: "Project 13",
    image: `${cowboy}`,   
  }
];
